import styled from '@emotion/styled';
import companyProfileService from 'api/companyProfileService';
import ContainedButton from 'common/ContainedButton';
import Frame from 'common/Frame';
import useCompanyServices from 'hooks/api/useCompanyServices';
import useTalentServices from 'hooks/api/useTalentServices';
import useDragEnd from 'hooks/useDragEnd';
import size from 'lodash/fp/size';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { portalActions } from 'store/portals';
import { profileActions, profileSelectors } from 'store/profile';
import { selectProfileProperty } from 'store/profile/profileSelectors';
import normalize from 'utils/normalize';
import { talentService } from '../../../api';
import useCompanyService from '../hooks/useCompanyService';
import CompanyService from './CompanyService';
import CompanyServiceForm from './CompanyServiceForm';
import CreateFirstService from './CreateFirstService';
import CreateServiceModal from './CreateServiceModal';

const StyledFrame = styled(Frame)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CompanyServices = () => {
  const dispatch = useDispatch();

  const isCompanyView = useSelector(profileSelectors.selectIsCompanyView);
  const profileId = useSelector(profileSelectors.selectActiveProfileId);
  const services = useSelector(selectProfileProperty('services'));
  const profileType = useSelector(selectProfileProperty('type'));
  const hasAddPermission = useSelector(profileSelectors.hasProfileEditPermission);

  const [currentService, setCurrentService] = useState(0);

  const { updateTalentService } = useTalentServices();
  const { updateCompanyService } = useCompanyServices();

  const { errors, initialize, mode, onFieldChange, onSubmit, submitting, updateMode, values } = useCompanyService();

  const onServiceAdd = ({ customName, service }) => {
    initialize({ service, customName });
    updateMode('add');
  };

  const onCancel = () => {
    setCurrentService(0);
    updateMode('view');
  };

  let deleteService = companyProfileService.deleteCompanyService;
  if (!isCompanyView) {
    deleteService = talentService.deleteTalentService;
  }

  const onDelete = service => {
    dispatch(
      portalActions.openPortal({
        name: 'confirm-modal',
        data: {
          title: 'Delete this Service ?',
          description: 'Are you sure you want to delete the service? This cannot be undone.',
          onConfirm: async () => {
            try {
              const res = await deleteService(profileId, service._id);
              const { success } = res.data;
              if (success) {
                dispatch(
                  profileActions.updateProfileProperty({
                    property: 'services',
                    value: service._id,
                    updateType: 'delete',
                    profileId
                  })
                );
              }
            } catch (err) {
              console.log('error: ', err);
            }
          }
        }
      })
    );
  };

  const { list, onDragEnd } = useDragEnd({
    items: services,
    callApi: (ids, items) => {
      const updateService = profileType === 'company' ? updateCompanyService : updateTalentService;
      return updateService(profileId, items).then(() => {
        dispatch(
          profileActions.updateProfileProperty({
            property: 'services',
            value: normalize(items),
            profileId
          })
        );
      });
    }
  });

  if (mode === 'add') {
    return (
      <>
        <CompanyServiceForm
          values={values}
          errors={errors}
          onFieldChange={onFieldChange}
          submitting={submitting}
          onSubmit={onSubmit}
          mode={mode}
          onCancel={onCancel}
        />
        <CreateServiceModal onAdd={onServiceAdd} />
      </>
    );
  }

  return (
    <>
      {!size(services) && hasAddPermission && <CreateFirstService />}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {list?.map((service, index) => (
                <div key={service._id} id={service._id}>
                  <Draggable
                    key={service._id}
                    draggableId={service._id}
                    index={index}
                    isDragDisabled={!hasAddPermission}
                  >
                    {provided => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <div
                          style={{
                            position: 'relative',
                            marginBottom: '24px'
                          }}
                        >
                          <CompanyService
                            showDragIcon={hasAddPermission && currentService !== service._id}
                            dragHandleProps={provided.dragHandleProps}
                            service={service}
                            page="services"
                            index={index}
                            onDelete={onDelete}
                            setCurrentService={setCurrentService}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                </div>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {size(services) > 0 && hasAddPermission && (
        <StyledFrame filled={false}>
          <ContainedButton icon="plus" type="button" opens="create-service-modal" backgroundColor="secondary">
            Add Service
          </ContainedButton>
        </StyledFrame>
      )}
      <CreateServiceModal onAdd={onServiceAdd} />
    </>
  );
};

export default CompanyServices;
