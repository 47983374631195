/* eslint-disable no-constant-condition */
import React, { useEffect, useState } from 'react';
import { talentService } from 'api';
import * as Styled from './StyledUserCard';
import WalletBadgeIcon from 'common/IconComponents/Badges/WalletBadgeIcon';
import FirstContractIcon from 'common/IconComponents/Badges/FirstContractIcon';
import ContainedButton from 'common/ContainedButton';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';

const PAYMENTS_ACTIVE = 'payments-active';

const getIcon = code => {
  switch (code) {
    case PAYMENTS_ACTIVE:
      return WalletBadgeIcon;
    case 'first-contract':
      return FirstContractIcon;
    default:
      return null;
  }
};

const getLabel = (code, isActive) => {
  switch (code) {
    case PAYMENTS_ACTIVE:
      return isActive ? 'Payments active' : 'Payments inactive';
    case 'first-contract':
      return '1st contract unlocked';
    default:
      return '';
  }
};

const UserCardAchievements = ({ profile }) => {
  const [loading, setLoading] = useState(false);
  const [achievements, setAchievements] = useState([]);
  const [options, setOptions] = useState([]);
  const hasPaymentsActive = achievements?.some(({ code }) => code === PAYMENTS_ACTIVE);
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);

  useEffect(() => {
    setLoading(true);
    const fetchAchievements = async () => {
      try {
        const {
          data: { talent, options: optionsData }
        } = await talentService.getTalentAchievements(profile.userId);
        setOptions(optionsData);
        if (talent?.achievements) {
          setAchievements(
            talent.achievements
              .filter(({ code }) => optionsData.some(o => o.code === code && o.enabled))
              .sort((a, b) => a.order - b.order)
          );
        } else {
          setAchievements([]);
        }
      } catch (error) {
        console.error('Error fetching achievements:', error);
        setAchievements([]);
      } finally {
        setLoading(false);
      }
    };

    fetchAchievements();
  }, [profile.userId]);

  // if there are no achievements and user does not have edit permission, return null
  if (loading || (!achievements.length && !hasEditPermission) || !options.length) {
    return null;
  }

  // To show grayded out icons for achievements that are not yet achieved.
  // Only for payment active badge
  if (hasEditPermission) {
    return (
      <>
        <Styled.FlexColumn style={{ gap: 8 }}>
          {options
            .sort((a, b) => a.order - b.order)
            .filter(option => option.code === PAYMENTS_ACTIVE || achievements.some(({ code }) => code === option.code))
            .map(option => {
              const Icon = getIcon(option.code);
              const isActive = achievements.some(({ code }) => code === option.code);
              if (!Icon) {
                return null;
              }
              return (
                <div data-tip data-for={`tooltip-${option.code}`} key={option.code}>
                  <Styled.FlexRow style={{ alignItems: 'center', gap: 8 }}>
                    <Icon active={isActive} />
                    {getLabel(option.code, isActive)}
                    {option.code === PAYMENTS_ACTIVE && !hasPaymentsActive && (
                      <ContainedButton
                        onClick={() => {
                          window.location.href = `${process.env.REACT_APP_NEXT_BASE_URL}/wallet`;
                        }}
                      >
                        Activate
                      </ContainedButton>
                    )}
                  </Styled.FlexRow>
                </div>
              );
            })}
        </Styled.FlexColumn>
        <Styled.Spacer />
      </>
    );
  }

  //   To show only achieved achievements.
  return (
    <>
      <Styled.FlexColumn style={{ gap: 8 }}>
        {achievements
          .filter(a => a.visible)
          .map(achievement => {
            const Icon = getIcon(achievement.code);

            return (
              <Styled.FlexRow style={{ alignItems: 'center', gap: 8 }} key={achievement.code}>
                <Icon active={true} />
                {getLabel(achievement.code, true)}
              </Styled.FlexRow>
            );
          })}
      </Styled.FlexColumn>
      <Styled.Spacer />
    </>
  );
};

export default UserCardAchievements;
